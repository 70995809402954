import React, { useState } from 'react';
import Introduction from '../../component/Introduction';
import Name from '../../component/Name';
import Email from '../../component/Email';
import ReceiverName from '../../component/ReceiverName';
import Preview from '../../component/Preview';
import SenderName from '../../component/SenderName';
import UploadImage from '../../component/UploadImage';
import CardLeft from '../../component/CardLeft';
import Edit from '../../component/Edit';
import Finish from '../../component/Finish';
import { db } from '../../firebase';
import EmailService from '../../services/EmailService';
import { emailTemplate } from '../../services/EmailService/helpers';

const initialState = {
  password: '',
  yourName: '',
  yourEmail: '',
  recipientName: '',
  recipientEmail: '',
  senderName: '',
  photoFile: '',
  photoUrl: '',
};

const InformationPage = () => {
  const [selectedState, setSelectedState] = useState(1);
  const [hasError, setHasError] = useState(false);
  const [initialStateForm, setInitialStateForm] = useState(initialState);
  const [authenticated, setAuthenticated] = useState(false);

  const handleFormChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'photoFile') {
      setInitialStateForm((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.files[0],
      }));
    } else {
      setInitialStateForm((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const clearImage = () => {
    setInitialStateForm((prevState) => ({
      ...prevState,
      photoFile: null,
      photoUrl: ''
    }));
  }

  const setImageUrl = (url) => {
    setInitialStateForm((prevState) => ({
      ...prevState,
      photoUrl: url,
    }));
  }
  const getCurrentContent = () => {
    const componentProps = {
      handleFormChange,
      selectedState,
      setSelectedState
    }
    if(selectedState === 1) {
      return <Introduction  {...{...componentProps, value: initialStateForm.password, setAuthenticated, setHasError: setHasError}} />
    }
    if(selectedState === 2) {
      return <Name {...{...componentProps, value: initialStateForm.yourName}}  />
    }
    if(selectedState === 3) {
      return <Email {...{...componentProps, value: initialStateForm.yourEmail}}  />
    }
    if(selectedState === 4) {
      return <ReceiverName {...{...componentProps, value: initialStateForm.recipientName}} />
    }
    if(selectedState === 5) {
      return <SenderName {...{...componentProps, value: initialStateForm.senderName}}  />
    }
    if(selectedState === 6) {
      return <UploadImage {...{...componentProps, value: initialStateForm.photoFile, clearImage, setImageUrl}}   />
    }
    if(selectedState === 7) {
      return <Edit {...{...componentProps, createRecord, value: initialStateForm.recipientEmail, values: initialStateForm}}  />
    }
    if(selectedState === 8) {
      return <Finish {...{...componentProps, clearCard}}  />
    }
  }

  const getCurrentGlogoText = () => {
    if(selectedState === 1) {
      if(hasError){
        return 'The password that was put in was incorrect. Please try again.'
      }
      return 'Type in your password'
    }

    if(selectedState === 2) {
      return 'Type in your full name.';
    }
    
    if(selectedState === 3) {
      return 'Type in your email to receive a confirmation email at the end.'
    }

    if(selectedState === 4) {
      return 'Type in the name(s) of who will be receiving the eCard. Use a comma and space between each name if it is being sent to more than one person.';
    }
    
    if(selectedState === 5) {
      return 'Type in the sender’s name, along with anyone else sending the eCard, so the recipient knows who it is from.'
    }

    if(selectedState === 6) {
      if(initialStateForm.photoFile) {

        return 'A photo file has been selected. If you choose that you do not want this photo, click ‘cancel’.'
      }
      return 'There is the option to include a photo in your eCard. Click on ‘choose file’ to select to photo you want. If you do not want to include a photo, click ‘skip’.';
    }

    if(selectedState === 7) {
      return 'The eCard is complete. If you are not satisfied with it, click ‘edit’ to alter it. Type in the email(s) of who you are sending the eCard to.'
    }

    if(selectedState === 8) {
      return 'The eCard has been sent out. You will receive a confirmation email, and the recipient will receive an email with a link to the eCard.'
    }
  }

  const createRecord = async(values) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getUTCDate()}/${currentDate.getUTCMonth() + 1}/${currentDate.getUTCFullYear()}`;
    const createRecordId = await db.ref(`/${process.env.REACT_APP_FIREBASE_NODE}`).push({...values, createdAt: formattedDate}).getKey();
    sendEmail(createRecordId);
  }

  const sendEmail = async (recordId) => {
    const {recipientEmail, yourEmail} = initialStateForm;
    const viewURL = `${process.env.REACT_APP_URL}/view/${recordId}`;
    const recipientData = {
      to: Array.isArray(recipientEmail)? recipientEmail.split(','): recipientEmail,
      bcc: ['kelsey.wheeler@bocodigital.com' ],
      from: 'support@bocodigital.com',
      replyTo: recipientEmail,
      subject: 'Your SMA Support Appreciation eCard!',
      message: emailTemplate(viewURL, 'recipient')
    }

    const senderData = {
      to: yourEmail,
      from: 'support@bocodigital.com',
      replyTo: yourEmail,
      subject: 'Your SMA Support Appreciation eCard!',
      message: emailTemplate(viewURL, 'sender')
    }

    try {
      const response = await EmailService.send(recipientData);
      const senderResponse = await EmailService.send(senderData);
      console.log(response, senderResponse, 'email')
    } catch (e) {
      console.log(e);
    }
    
  }
  const clearCard = () => {
    setInitialStateForm({...initialState});
    setSelectedState(2);
  }

  const getCard = () => {
    if(selectedState === 4 || selectedState === 5 || selectedState === 6) {
      return <Preview values={initialStateForm} showImage={false} />
    }
    if(selectedState === 7 || selectedState === 8) {
      return <>
        <CardLeft values={initialStateForm} />
        <Preview values={initialStateForm} />
      </>
    }
  }

  const handleSelectionBasedonCondition = (nextSelectedState) => {
    if(!authenticated){
      return;
    }
    if(nextSelectedState < selectedState && nextSelectedState !== 1) {
      setSelectedState(nextSelectedState);
    }
  }

  return (
    <body className={`state-${selectedState}`}>
      <div className="bar top" />
      <div className="header">
        <a className="logo" />
        <div className="breadcrumbs">
          <ul>
            {Array.from({ length: 8 }, (_v, i) => (
              <li className={`item-${i+1}`} onClick={()=> handleSelectionBasedonCondition(i+1)} />
            ))}
          </ul>
        </div>
      </div>
      <div className="body">
        <div className={`panel panel-${selectedState}`}>
          {getCurrentContent()}
        </div>
        {getCard()}
      </div>
      <div className="glogo" />
      <div className="glogo-left">{getCurrentGlogoText()}</div>
      <div className="bar bottom" />
    </body>
  );
};

export default InformationPage;
