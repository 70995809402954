import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { validateEmail } from '../services/EmailService/helpers';

const Email = ({
  handleFormChange,
  selectedState,
  setSelectedState,
  value,
}) => {
  const [showError, setShowError] = useState(false);

  const handleStateChange = () => {
    if (value && validateEmail(value)) {
      setSelectedState(selectedState + 1);
    } else {
      setShowError(true);
    }
  };
  return (
    <div className="content">
      <div className="input">
        <label for="yourEmail">
          <input
            id="yourEmail"
            type="email"
            name="yourEmail"
            placeholder="Enter Your Genentech Email Address"
            onChange={handleFormChange}
            value={value}
          />
        </label>
        <div className="btn btn-arrow" onClick={handleStateChange}></div>
      </div>
      {showError && <div className="error">INVALID EMAIL</div>}
    </div>
  );
};

Email.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  selectedState: PropTypes.number.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default Email;
