import React from 'react';
import PropTypes from 'prop-types';

const Finish = ({clearCard}) => {
  return (
    <div class="content">
      <h3>YOU ROCK!</h3>
      <p>Your eCard has been sent!</p>
      <p>
        You will receive an email confirmation with a link to view the eCard.
      </p>
      <div class="bracket-link again">
        [<a onClick={clearCard}>Create Another eCard?</a>]
      </div>
    </div>
  );
};

Finish.propTypes = {
  clearCard: PropTypes.func.isRequired
}

export default Finish;
