export const emailTemplate = function generateEmailHTML(viewURL, userType) {
  // this is a horrible speed hack
	var str = "";
  str += "";
  str += "<!DOCTYPE html PUBLIC \"-//W3C//DTD XHTML 1.0 Transitional//EN\" \"http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd\">";
  str += "<html xmlns=\"http://www.w3.org/1999/xhtml\">";
  str += "<head>";
  str += "<meta name=\"viewport\" content=\"width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=no;\"/>";
  str += "<meta name=\"apple-mobile-web-app-capable\" content=\"yes\"/>";
  str += "<meta name=\"apple-mobile-web-app-status-bar-style\" content=\"black\"/>";
  str += "<meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\"/>";
  str += "<meta http-equiv=\"X-UA-Compatible\" content=\"IE=X\" />";
  str += "<title>Share</title>";
  str += "";
  str += "";
  str += "<style type=\"text/css\">";
  str += "";
  str += "table, tr, td {";
  str += "    border-spacing: 0;";
  str += "    border-collapse: collapse;";
  str += "    box-sizing: border-box;";
  str += "}";
  str += "a img, img {";
  str += "    border: none;";
  str += "}";
  str += "";
  str += "span.no_wrap {";
  str += "    white-space: nowrap;";
  str += "}";
  str += "";
  str += ".ExternalClass * {line-height: 100% !important; mso-line-height-rule:exactly;}";
  str += "";
  str += "</style>";
  str += "";
  str += "</head>";
  str += "";
  str += "<body class=\"body\" bgcolor=\"#FFFFFF\" style=\"margin:0 auto;padding:0;-webkit-font-smoothing: antialiased;-webkit-text-size-adjust: none;height: 100%;color:#604e3e;width:100%;\">";
  str += "";
  str += "";
  str += "  <table height=\"100%\" width=\"100%\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\">";
  str += "    <tr>";
  str += "      <td valign=\"top\" align=\"left\">";
  str += "";
  str += "   <!--START VIEW BROWSER-->";
  str += "    <table style=\"max-width: 600px; width:100%;\" border=\"0\" align=\"center\" cellpadding=\"0\" cellspacing=\"0\">";
  str += "    <!--START HEADER-->";
  str += "        <tr>";
  str += "            <td align=\"left\" height=\"18\" bgcolor=\"#700c71\"><span style=\"font-size: 10px; height: 18px;\">&nbsp;</span></td>";
  str += "        </tr>";
  str += "";
  str += "    <!--START BODY-->";
  str += "        <tr>";
  str += "            <td align=\"left\">";
  str += "                <table style=\"width:100%;\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\">";
  str += "                    <tr>";
  str += "                        <td style=\"width:10%;\"><img src=\"https://bocoweb.bocodigital.com/spacer.gif\" alt=\"\" height=\"10\" style=\"display:block;\" /></td>";
  str += "                        <td align=\"left\">";
  str += "                            <table style=\"width:100%;\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\">";
  str += "                                <tr>";
  str += "                                    <td valign=\"top\" height=\"80\" style=\"line-height:13px;mso-line-height-rule:exactly;height:80px;\"></td>";
  str += "                                </tr>";
  str += "                                <tr>";
  str += "                                    <td align=\"left\">";
  str += "                                        <font size=\"3\" color=\"#1f0f0f\" face=\"Helvetica,Arial,Verdana,sans-serif\" style=\"font-size: 12px; font-weight:normal; display:block;\">";
  str += "                                            <span style=\"font-size: 12px; color: #1f0f0f;\">Hello!</span>";
  str += "                                        </font>";
  str += "                                    </td>";
  str += "                                </tr>";
  str += "                                <tr>";
  str += "                                    <td valign=\"top\" height=\"20\" style=\"line-height:13px;mso-line-height-rule:exactly;\"></td>";
  str += "                                </tr>";
  str += "                                <tr>";
  str += "                                    <td align=\"left\">";
  str += "                                        <font size=\"3\" color=\"#1f0f0f\" face=\"Helvetica,Arial,Verdana,sans-serif\" style=\"font-size: 12px; font-weight:normal; display:block;\">";

  if(userType == 'recipient') {
    str += "                                            <span style=\"font-size: 12px; color: #1f0f0f;\">You have received a SMA Support Appreciation eCard from your Genentech Team!</span>";
  } else {
    str += "                                            <span style=\"font-size: 12px; color: #1f0f0f;\">Your SMA Support Appreciation eCard was sent!</span>";
  }

  str += "                                        </font>";
  str += "                                    </td>";
  str += "                                </tr>";
  str += "                                <tr>";
  str += "                                    <td valign=\"top\" height=\"20\" style=\"line-height:13px;mso-line-height-rule:exactly;\"></td>";
  str += "                                </tr>";
  str += "                                <tr>";
  str += "                                    <td align=\"left\">";
  str += "                                        <font size=\"3\" color=\"#1f0f0f\" face=\"Helvetica,Arial,Verdana,sans-serif\" style=\"font-size: 12px; font-weight:normal; display:block;\">";
  str += "                                            <span style=\"font-size: 12px; color: #1f0f0f;\">Click the button below to view.</span>";
  str += "                                            <p style=\"font-size: 12px; font-style: italic; color: #1f0f0f;\">This content is intended to be viewed on laptop or desktop.</p>";
  str += "                                        </font>";
  str += "                                    </td>";
  str += "                                </tr>";
  str += "                                <tr>";
  str += "                                    <td valign=\"top\" height=\"65\" style=\"line-height:13px;mso-line-height-rule:exactly;\"></td>";
  str += "                                </tr>";
  str += "                                <tr>";
  str += "                                    <td align=\"center\">";
  str += "                                        <table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\">";
  str += "                                          <tr>";
  str += "                                            <td align=\"center\">";
  str += "                                              <table border=\"0\" cellspacing=\"0\" cellpadding=\"0\">";
  str += "                                                <tr>";
  str += "                                                  <td>";
  str += "                                                    <a href=\"" + viewURL + "\" target=\"_blank\" style=\"font-size: 12px; font-weight: bold; font-family: Helvetica,Arial,Verdana,sans-serif; color: #ffffff; text-decoration: none; border-radius: 3px; -webkit-border-radius: 3px; -moz-border-radius: 3px; background-color: #6d2077; border-top: 12px solid #6d2077; border-bottom: 12px solid #6d2077; border-right: 26px solid #6d2077; border-left: 26px solid #6d2077; display: inline-block;\">VIEW MY eCARD!</a>";
  str += "                                                  </td>";
  str += "                                                </tr>";
  str += "                                              </table>";
  str += "                                            </td>";
  str += "                                          </tr>";
  str += "                                        </table>";
  str += "                                    </td>";
  str += "                                </tr>";
  str += "                                <tr>";
  str += "                                    <td valign=\"top\" height=\"20\" style=\"line-height:13px;mso-line-height-rule:exactly;\"></td>";
  str += "                                </tr>";
  str += "                            </table>";
  str += "                        </td>";
  str += "                        <td style=\"width:10%;\"><img src=\"https://bocoweb.bocodigital.com/spacer.gif\" alt=\"\" height=\"10\" style=\"display:block;\" /></td>";
  str += "                    </tr>";
  str += "                </table>";
  str += "            </td>";
  str += "        </tr>";
  str += "    </table>";
  str += "</td>";
  str += "</tr>";
  str += "";
  str += "";
  str += "";
  str += "</table>";
  str += "</div>";
  str += "";
  str += "</body>";
  str += "</html>";

  return str;
};

export const validateEmail = (email) => {
  return String(email)
    .trim()
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
