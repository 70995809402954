import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {storage} from  '../firebase';

const UploadImage = ({
  handleFormChange,
  selectedState,
  setSelectedState,
  value,
  clearImage,
  setImageUrl
}) => {
  const [loading, setLoading] = useState(false);
  const inputFile = useRef(null);
  const handleStateChange = async() => {
    setLoading(true);
    //initiates the firebase side uploading 
    const ref = storage.ref(`/images/${value.name}`);
    await ref.put(value);
    const url = await ref.getDownloadURL();
    setImageUrl(url);
    setLoading(false);
    setSelectedState(selectedState+1);
  };
  return (
    <div className="content">
      <div className="input">
        <label for="photo">
          <input
            id="photo"
            type="text"
            name="photo"
            placeholder="(Optional) Upload Photo"
            disabled="disabled"
            value={value?.name || ''}
            style={{opacity: loading? 0.5: 1}}
          />
          <input
            id="photoFile"
            accept=""
            type="file"
            name="photoFile"
            onChange={handleFormChange}
            ref={inputFile}
          />
        </label>
        <div className="btn" style={{cursor: loading? 'not-allowed': 'pointer'}} onClick={() => handleStateChange()}></div>
      </div>
      {!value && (
        <div className="bracket-link choose">
          [<a onClick={() => inputFile.current.click()}>choose file</a>]
        </div>
      )}
      <div className="bracket-link skip">
        [<a onClick={() => setSelectedState(selectedState + 1)}>skip</a>]
      </div>
      {value && (
        <div className="bracket-link cancel">
          [
          <a onClick={clearImage}>
            cancel
          </a>
          ]
        </div>
      )}
      <div className="note">
        Photos uploaded to your Nurses Rock eCard should represent your Nurses
        Rock activities. (i.e individual or teams participating in Nurses Rock
        activities) and reflect appropriate taste and tone for business use.
        Please ask permission of the individuals in the photo to use it for this
        purpose before doing so.
      </div>
    </div>
  );
};

UploadImage.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  selectedState: PropTypes.number.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  clearImage: PropTypes.func.isRequired,
  setImageUrl: PropTypes.func.isRequired,
}

export default UploadImage;
