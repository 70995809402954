import React from 'react';
import PropTypes from 'prop-types';
import defaultImage from'../assets/images/thankyou.png';

const CardLeft = ({values, showImage = true}) => {
  return (
    <div className="preview">
      <div className="to">
        To: <span>{values.recipientName}</span>
      </div>
      <div className="middle">
        <div className="copy"></div>
        {showImage && <div className="image">
          <img src={values?.photoUrl ? values.photoUrl: defaultImage } />
        </div>}
      </div>
      <div className="from">
        From:<span>{values.senderName}</span>
        <span>{values.yourEmail}</span>
      </div>
    </div>
  );
};

CardLeft.propTypes = {
  values: PropTypes.object.isRequired,
}

export default CardLeft;
