import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { validateEmail } from '../services/EmailService/helpers';

const Edit =  ({
  createRecord,
  handleFormChange,
  selectedState,
  setSelectedState,
  value,
  values
}) => {
  const [showError, setShowError] = useState(false);

  const handleStateChange = async() => {
    if(!value) {
      setShowError(true);
      return;
    }
    const emails = value.split(',');
    const validEmails = emails.every(v => validateEmail(v));
    if(!validEmails) {
      setShowError(true);
      return;
    }
    delete values.photoFile;
    delete values.password;
    await createRecord(values);
    setSelectedState(selectedState + 1);
   
  };

  return (
    <div class="content">
      <div class="bracket-link edit">
        [<a onClick={()=> setSelectedState(2)}>edit</a>]
      </div>
      <div class="input">
        <label for="recipientEmail">
          <input
            id="recipientEmail"
            type="text"
            name="recipientEmail"
            placeholder="Enter Recipient Emails(s)"
            onChange={handleFormChange}
          />
        </label>
      </div>
      {showError && <div class="error">INVALID EMAIL(s)</div>}
      <div class="copy">
        <p>Use a comma to separate email addresses.</p>
        <p>
          eCards will be logged/saved on a system accessible to the Genentech
          team for purposes of future internal use.
        </p>
      </div>
      <button class="btn btn-lg btn-block btn-red" onClick={handleStateChange}>Send Now?</button>
    </div>
  );
};

Edit.propTypes = {
  createRecord: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  selectedState: PropTypes.number.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
}

export default Edit;
