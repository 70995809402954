import React, { useState } from 'react';
import PropTypes from 'prop-types';

const RecipientName = ({handleFormChange, selectedState,setSelectedState, value}) => {
  const [showError, setShowError ] = useState(false);
  const handleStateChange = () => {
    if(value){
      setSelectedState(selectedState+1);
    }else {
      setShowError(true)
    }
  }
  return (
    <div className="content">
      <div className="input">
        <label for="recipientName">
          <input
            id="recipientName"
            type="text"
            name="recipientName"
            placeholder="Enter Recipient Name(s)"
            onChange={handleFormChange}
            value={value}
          />
        </label>
        <div className="btn btn-arrow" onClick={handleStateChange}></div>
      </div>
      {showError && <div className="error">REQUIRED</div>}
    </div>
  );
};

RecipientName.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  selectedState: PropTypes.number.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default RecipientName;
